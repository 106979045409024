import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "detail_heading" }
const _hoisted_2 = { class: "detail_type" }
const _hoisted_3 = { class: "detail_description" }
const _hoisted_4 = ["href"]

import listData from "@/assets/data/list.json";
import Stack from "@/components/layouts/Stack.vue";
import { textCompile } from "@/lib/textCompile";
import type { typeListData } from "@/types/listData";


export default /*@__PURE__*/_defineComponent({
  __name: 'Detail',
  setup(__props) {

const lists: Array<typeListData> = listData;

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("article", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(lists), (list) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (_ctx.$route.path === '/work/' + list.id + '/detail')
          ? (_openBlock(), _createElementBlock("div", {
              class: "detail",
              style: _normalizeStyle({ backgroundImage: 'url(' + list.image + ')' }),
              key: list.id
            }, [
              _createElementVNode("h2", _hoisted_1, [
                _createTextVNode(" No." + _toDisplayString(list.id) + " - " + _toDisplayString(list.title) + " ", 1),
                _createElementVNode("span", _hoisted_2, _toDisplayString(list.type), 1)
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(Stack, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(textCompile)(list.desc), (text) => {
                      return (_openBlock(), _createElementBlock("p", { key: text }, _toDisplayString(text), 1))
                    }), 128))
                  ]),
                  _: 2
                }, 1024)
              ]),
              _createElementVNode("a", {
                href: list.url,
                target: "_blank"
              }, _toDisplayString(list.url), 9, _hoisted_4)
            ], 4))
          : _createCommentVNode("", true)
      ], 64))
    }), 256)),
    _createVNode(_component_router_link, {
      class: "detail_btn",
      to: "/"
    }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode("Back Home")
      ])),
      _: 1
    })
  ]))
}
}

})