import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "detail-404" }

import Cover from "@/components/layouts/Cover.vue";
import Imposter from "@/components/layouts/Imposter.vue";

export default /*@__PURE__*/_defineComponent({
  __name: '404',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(Cover, null, {
    default: _withCtx(() => [
      _createVNode(Imposter, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "detail-404-heading" }, "404", -1)),
            _createVNode(_component_router_link, {
              class: "detail_btn",
              to: "/"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Back Home")
              ])),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})