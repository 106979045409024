import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "textfield" }
const _hoisted_3 = {
  class: "textfield_label",
  for: "search"
}
const _hoisted_4 = { class: "container" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "card_detail" }
const _hoisted_7 = { class: "no-result" }
const _hoisted_8 = { class: "logo" }

import { ref, computed } from "vue";
import Cover from "@/components/layouts/Cover.vue";
import Grid from "@/components/layouts/Grid.vue";
import listData from "@/assets/data/list.json";
import type { typeListData } from "@/types/listData";


export default /*@__PURE__*/_defineComponent({
  __name: 'Main',
  setup(__props) {

const filterKey = ref("");
const lists: Array<typeListData> = listData;
const filterItems = computed(() => lists.filter(list => {
  const searchRegex = new RegExp(filterKey.value, "i");
  return (
    searchRegex.test(list.title) ||
    searchRegex.test(list.type) ||
    searchRegex.test(list.desc)
  );
}));

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _createVNode(Cover, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("p", {
              class: "hidden-text",
              id: "search_text"
            }, " Input form to search for project ", -1)),
            _withDirectives(_createElementVNode("input", {
              id: "search",
              "aria-describedby": "search_text",
              class: "textfield_input",
              type: "text",
              placeholder: "Search Words...",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((filterKey).value = $event))
            }, null, 512), [
              [_vModelText, filterKey.value]
            ])
          ])
        ]),
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _createVNode(Grid, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filterItems.value, (list) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "card",
                  key: list.id
                }, [
                  _createVNode(_component_router_link, {
                    to: { name: 'Works', params: { number: list.id } },
                    class: _normalizeClass('card_img' + list.id + ' card_img')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: list.image,
                        alt: list.title
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 2
                  }, 1032, ["to", "class"]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_router_link, {
                      to: { name: 'Works', params: { number: list.id } },
                      class: "filetype"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("[" + _toDisplayString(list.type) + "]", 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ])
                ]))
              }), 128))
            ]),
            _: 1
          })
        ], 512), [
          [_vShow, filterItems.value.length !== 0]
        ]),
        _withDirectives(_createElementVNode("h2", _hoisted_7, _cache[2] || (_cache[2] = [
          _createElementVNode("span", null, "No results.", -1),
          _createElementVNode("span", null, "\\(^Д^)/", -1)
        ]), 512), [
          [_vShow, filterItems.value.length === 0]
        ])
      ]),
      _: 1
    }),
    _createElementVNode("p", _hoisted_8, [
      _createVNode(_component_router_link, {
        to: "/profile",
        class: "logo-link"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("img", {
            src: "/icon_beta.png",
            alt: "profile Detail"
          }, null, -1)
        ])),
        _: 1
      })
    ])
  ]))
}
}

})